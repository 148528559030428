* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  height: 100%;
}

#root {
  height: 100%;
}

.MuiPaper-root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: 100dvh;
  box-shadow: none;
}

.MuiList-root{
  flex: 1;
}

li.MuiListItem-root.user{
  align-items: flex-end;
}

.MuiListItem-root .MuiListItemText-root .MuiListItemText-primary{
  border-radius: 24px !important;
}

li.MuiListItem-root.user .MuiListItemText-primary{
  position: relative; 
  color: #1e1e1e;
  background-color: #8EE386;
}

li.MuiListItem-root.user .MuiListItemText-primary:after {
  content: "";
  position: absolute;
  top: 3px; 
  right: -19px;
  border: 8px solid transparent;
  border-left: 18px solid #8EE386;
  -webkit-transform: rotate(-35deg);
  transform: rotate(-35deg);
}


li.MuiListItem-root.assistant{
  align-items: flex-start;
}

li.MuiListItem-root.assistant .MuiListItemText-primary{
  position: relative; 
  background-color:#FFFFFF;
  color: #1e1e1e;
}

li.MuiListItem-root.assistant .MuiListItemText-primary:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 3px; 
  left: -19px;
  border: 8px solid transparent;
  border-right: 18px solid #FFFFFF;
  -webkit-transform: rotate(35deg);
  transform: rotate(35deg);
}

.MuiOutlinedInput-root{
  border-radius: 9999px !important;
  background-color: #F5F5F5;
  border-color: #E7EDF7;
}